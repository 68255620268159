@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'TTNormsPro';
    src: url('/assets/fonts/TTNormsPro-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro';
    src: url('/assets/fonts/TTNormsPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro';
    src: url('/assets/fonts/TTNormsPro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'TTNormsPro';
    src: url('/assets/fonts/TTNormsPro-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}